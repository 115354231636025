import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, Stack, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WatchlistSearchItem from "../watchlist/WatchlistSearchItem";
import { useState, useCallback, useEffect } from "react";
import { searchStocks } from "../../hooks/useGetScripByName";
import debounce from "lodash/debounce";

const style = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  maxHeight: "600px",
  width: "100%",
  maxWidth: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  right: 4,
  top: 2,
  color: (theme) => theme.palette.grey[500],
};

const marketTypes = [
  { label: "all", value: "all" },
  { label: "stock", value: "stock" },
  // { label: 'commodity', value: 'cfd' },
  { label: "forex", value: "forex" },
  { label: "futures", value: "futures" },
  { label: "index", value: "index" },
];

const debouncedSearch = debounce((fn) => fn(), 300);

const SearchStockModal = ({ open, handleSearchModal, setUserWatchlist }) => {
  const [inputStock, setInputStock] = useState("");
  const [marketType, setMarketType] = useState(marketTypes[0].value);
  const [stocks, setStocks] = useState([]);

  const handleSearchStock = useCallback(async () => {
    try {
      const searchedStocks = await searchStocks(inputStock, marketType);
      setStocks(searchedStocks);
    } catch (error) {
      console.error("Error searching stocks:", error);
      alert("Internal server error");
    }
  }, [inputStock, marketType]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputStock(value.toUpperCase());
  };

  const handleModalClose = () => {
    setInputStock("");
    handleSearchModal(false);
  };

  useEffect(() => {
    if (marketType && inputStock) {
      debouncedSearch(handleSearchStock);
    }
    // Cleanup function to cancel any outstanding debounced calls
    return () => {
      debouncedSearch.cancel();
    };
  }, [marketType, inputStock, handleSearchStock]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={closeButtonStyle}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Stack direction="row" spacing={1}>
              <TextField
                sx={{
                  width: "80%",
                }}
                value={inputStock}
                onChange={handleChange}
                color="secondary"
                id="outlined-basic"
                label="Search stock"
                variant="outlined"
                focused
              />
              <Button
                onClick={handleSearchStock}
                sx={{
                  color: "#fff",
                  background: "#D43725",
                  fontSize: "0.9rem!important",
                  padding: "0.5rem 2rem",
                  "&:hover": {
                    background: "#D43725",
                    opacity: 0.8,
                  },
                }}
              >
                Search
              </Button>
            </Stack>
          </Box>
          <Box mt={2}>
            <Stack direction="row" spacing={1}>
              {marketTypes.map((type) => (
                <Chip
                  key={type.label}
                  label={type.label}
                  size="small"
                  onClick={() => setMarketType(type.value)}
                  color={marketType === type.value ? "secondary" : "default"}
                />
              ))}
            </Stack>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: "530px",
              margin: "1rem 0rem",
            }}
          >
            {inputStock && stocks?.length > 0 ? (
              <Stack>
                {stocks ? (
                  stocks.map((stock) => (
                    <WatchlistSearchItem
                      key={`${stock.id}${stock.description}${stock.type}`}
                      stock={stock}
                      setInputStock={setInputStock}
                      setUserWatchlist={setUserWatchlist}
                      setStocks={setStocks}
                    />
                  ))
                ) : (
                  <Typography>No Stocks found.</Typography>
                )}
              </Stack>
            ) : null}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchStockModal;
