import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Loading from "../loading/Loading";
import RefreshIcon from "@mui/icons-material/Refresh";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PlayerContext } from "../../contexts/PlayerProvider";
import { placeOrder } from "../../hooks/usePlaceOrder";
const style = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "90%" : 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  padding: isMobile ? 2 : 3,
});

export default function BuySellModal({ orderType, open, setOpen, stock }) {
  const [productType, setProductType] = useState("NRML");
  const [priceType, setPriceType] = useState("Market");
  const [qty, setQty] = useState("1");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [marginRequired, setMarginRequired] = useState(0.0);
  const { setPlayer } = useContext(PlayerContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    setQty(1);
    setPrice(0);
    setOpen(false);
  };

  const handlePriceType = (e) => {
    setPriceType(e.target.value);
  };

  const handleProductType = (e) => {
    setProductType(e.target.value);
  };

  const handleQtyChange = (e) => {
    setQty(e.target.value);
  };

  const handleOnOrder = async () => {
    if (!productType || !priceType) {
      alert(`You haven't chosen any one`);
      return;
    }

    const data = {
      stockId: stock.scriptId._id,
      orderType: orderType,
      priceType: priceType,
      productType: productType,
      qty: qty,
      price: price,
      stockPrice: stock.scriptId.close,
    };

    setLoading(true);

    try {
      const response = await placeOrder(data);
      if (response.success) {
        setLoading(false);
        setPlayer(prevData => ({...prevData, data: {...prevData.data, availableFunds: response.data.availableFunds}}))
        handleClose();
        alert(
          priceType.toLowerCase() === "market"
            ? "Order completed successfully"
            : "Order placed successfully"
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      const response = error?.response;
      console.log(response);
      if (response?.status === 400) {
        switch (response?.data?.errorCode) {
          case "INSUFFICIENT_FUNDS":
            alert("Insufficient funds");
            break;
          case "ROOM_TIME_ENDED":
            alert("Room time has ended. You cannot place orders now.");
            break;
          case "ORDER_LIMIT_REACHED":
            alert("You have reached your order limit for this room.");
            break;
          case "MARKET_CLOSED":
            alert("Market is closed. You cannot place orders now.");
            break;
          default:
            alert("Something went wrong");
        }
      } else {
        alert("Something went wrong");
      }
    }
  };

  const handleRefreshMargin = () => {
    let marginReq =
      qty *
      (priceType.toLowerCase() === "market" ? stock.scriptId.lastPrice : price);
    setMarginRequired((marginReq / 1).toFixed(2));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style(isMobile)}>
        <Stack
          sx={{
            background:
              orderType.toLowerCase() === "buy" ? "#396dff" : "#d43725",
            p: 2,
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "600",
            }}
          >
            {orderType} {stock?.scriptId?.symbol}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "0.8rem",
            }}
          >
            {stock?.scriptId?.exchange}: ₹{stock?.scriptId?.lastPrice}
          </Typography>
        </Stack>
        <Stack sx={{ px: 2, my: 2 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="NRML"
                control={
                  <Radio
                    checked={true}
                    onChange={handleProductType}
                    color="blue"
                  />
                }
                label="NRML"
              />
            </RadioGroup>
          </FormControl>
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={1}
            alignItems="center"
            sx={{
              my: 2,
            }}
          >
            <TextField
              value={qty}
              onChange={handleQtyChange}
              sx={{
                width: "100%",
              }}
              color="secondary"
              id="cmQty"
              label="Qty (Lot Size 1)"
              variant="outlined"
              name="qty"
            />
          </Stack>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Market"
                control={
                  <Radio
                    checked={true}
                    onChange={handlePriceType}
                    color="blue"
                  />
                }
                label="Market"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: "#d9d9d950",
            p: 2,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              sx={{
                color: "grey",
                fontSize: "0.9rem",
              }}
            >
              Margin required: ₹{marginRequired}{" "}
            </Typography>
            <RefreshIcon
              sx={{
                color:
                  orderType.toLowerCase() === "buy" ? "#396dff" : "#d43725",
                width: "18px",
                heigth: "18px",
                cursor: "pointer",
              }}
              onClick={handleRefreshMargin}
            />
          </Stack>
          <Stack
            justifyContent="flex-end"
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={handleOnOrder}
              variant="contained"
              color={orderType.toLowerCase() === "buy" ? "blue" : "brand"}
              sx={{
                width: 80,
                color: "#fff",
                boxShadow: "none",
                "&:hover": {
                  opacity: "0.9",
                  boxShadow: "none",
                },
              }}
            >
              {loading ? <Loading color="#fff" /> : orderType}
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                width: 100,
                color: "grey",
                boxShadow: "none",
                border: "1px solid grey",
                "&:hover": {
                  opacity: "0.9",
                  boxShadow: "none",
                  border: "1px solid grey",
                },
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
