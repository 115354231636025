import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getPlayerTotalProfitLoss } from './utils';

const LeaderBoardTable = ({ leaderBoard }) => {
  const [stringifiedLeaderBoard] = useState(JSON.stringify(leaderBoard));
  const [sortedLeaderBoard, setSortedLeaderBoard] = useState([]);

  useEffect(() => {
    const sorted = leaderBoard?.sort((a, b) => {
      return getPlayerTotalProfitLoss(b) - getPlayerTotalProfitLoss(a);
    });
    setSortedLeaderBoard(sorted || []);
  }, [leaderBoard, stringifiedLeaderBoard]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Player Name</TableCell>
            <TableCell align="right">Total P/L</TableCell>
            <TableCell align="right">Available Funds</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedLeaderBoard?.map((player) => {
            const playerTotalPnL = getPlayerTotalProfitLoss(player);

            return (
              <TableRow
                key={player._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 }
                }}
              >
                <TableCell component="th" scope="row">
                  {player.userId.fullName}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: playerTotalPnL > 0 ? '#0cd46a' : '#d43725'
                  }}
                >
                  {playerTotalPnL}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: parseFloat(player.availableFunds).toFixed(2) > 0 ? '#0cd46a' : '#d43725'
                  }}
                >
                  {player.availableFunds.toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderBoardTable;
