import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import BuySellModal from "../modals/BuySellModal";
import WatchlistItem from "./WatchlistItem";
import SearchStockModal from "../modals/SearchStockModal";

function Watchlist({ userWatchlist, setUserWatchlist, setShowWatchlist }) {
  const [open, setOpen] = useState(false);
  const [stock, setStock] = useState({});
  const [orderType, setOrderType] = useState("");
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const handleSearchModal = (type) => {
    setSearchModalOpen(type);
  };

  return (
    <Box sx={{ width: "100%", height: "600px" }}>
      <BuySellModal
        open={open}
        setOpen={setOpen}
        orderType={orderType}
        stock={stock}
      />
      <SearchStockModal
        open={searchModalOpen}
        handleSearchModal={handleSearchModal}
        setUserWatchlist={setUserWatchlist}
      />
      <Box>
        <Stack direction="row" spacing={1}>
          <TextField
            sx={{
              width: "100%",
            }}
            onClick={() => handleSearchModal(true)}
            color="secondary"
            id="outlined-basic"
            label="Search stock"
            variant="outlined"
          />
        </Stack>
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          height: "530px",
          margin: "1rem 0rem",
        }}
      >
        <Stack>
          {userWatchlist?.length > 0 ? (
            userWatchlist?.map((stock) => (
              <WatchlistItem
                key={stock._id}
                stock={stock}
                setStock={setStock}
                setOrderType={setOrderType}
                setOpen={setOpen}
                setUserWatchlist={setUserWatchlist}
                setShowWatchlist={setShowWatchlist}
              />
            ))
          ) : (
            <Typography>No stocks in watchlist.</Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default Watchlist;
