import { useState, useCallback } from "react";
import axios from "axios";

const useGetAllWatchList = () => {
  const [userWatchlist, setUserWatchlist] = useState([]);

  const fetchUserWatchList = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/twelve-candles/watchlist`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        console.log("watchlist", response.data.data);
        setUserWatchlist(response.data.data);
      }
    } catch (err) {
      console.error(err);
      alert("Internal server error");
    }
  }, []);

  return {
    userWatchlist,
    fetchUserWatchList,
    setUserWatchlist,
  };
};

export default useGetAllWatchList;
