import React, { useState, createContext } from 'react'

export const PlayerContext = createContext()

const PlayerProvider = ({ children }) => {
	const [player, setPlayer] = useState(0)

	return (
		<PlayerContext.Provider value={{ player, setPlayer }}>{children}</PlayerContext.Provider>
	)
}

export default PlayerProvider
