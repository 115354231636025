import React from 'react'
import { createChart } from 'lightweight-charts'
import PropTypes from 'prop-types'
import { timeToLocal } from '../../helpers/time'

const HEIGHT = 700

let chart
let candlestickSeries

const CandleChart = ({ legend, initCandles, lastCandle, decimals }) => {
	const chartRef = React.useRef()
	const legendRef = React.useRef()

	React.useEffect(() => {
		chart = createChart(chartRef.current, {
			width: chartRef.current.offsetWidth,
			height: HEIGHT,
			alignLabels: true,
			timeScale: {
				rightOffset: 5,
				barSpacing: 15,
				fixLeftEdge: false,
				lockVisibleTimeRangeOnResize: true,
				rightBarStaysOnScroll: true,
				borderVisible: false,
				borderColor: '#fff000',
				visible: true,
				timeVisible: true,
				secondsVisible: false
			},
			rightPriceScale: {
				scaleMargins: {
					top: 0.3,
					bottom: 0.25
				},
				borderVisible: false
			},
			priceScale: {
				autoScale: true
			},
			watermark: {
				color: 'rgba(0, 0, 0, 0.5)',
				visible: true,
				text: 'TheSiriusAcademy',
				fontSize: 12,
				horzAlign: 'left',
				vertAlign: 'bottom'
			}
		})

		candlestickSeries = chart.addCandlestickSeries({
			priceScaleId: 'right',
			upColor: '#00AA00',
			downColor: '#AA0000',
			borderVisible: false,
			wickVisible: true,
			borderColor: '#000000',
			wickColor: '#000000',
			borderUpColor: '#00AA00',
			borderDownColor: '#AA0000',
			wickUpColor: '#00AA00',
			wickDownColor: '#AA0000',
			priceFormat: {
				type: 'custom',
				minMove: '0.00000001',
				formatter: (price) => {
					return parseFloat(price).toFixed(5)
				}
			}
		})

		candlestickSeries.setData(
			initCandles?.map((candle) => {
				const newCandle = { ...candle }
				newCandle.time = timeToLocal(newCandle.time)
				return newCandle
			})
		)
	}, [])

	React.useEffect(() => {
		const newLastCandle = { ...lastCandle }
		newLastCandle.time = timeToLocal(newLastCandle.time)
		if (
			newLastCandle &&
			newLastCandle?.time &&
			newLastCandle?.open &&
			newLastCandle?.high &&
			newLastCandle?.low &&
			newLastCandle?.close &&
			newLastCandle?.time >= initCandles[initCandles.length - 1]?.time
		) {
			candlestickSeries.update(newLastCandle)
		}
	}, [lastCandle])

	React.useEffect(() => {
		const handler = () => {
			chart.resize(chartRef.current.offsetWidth, HEIGHT)
		}
		window.addEventListener('resize', handler)
		return () => {
			window.removeEventListener('resize', handler)
		}
	}, [])

	return (
		<div ref={chartRef} id='chart'>
			<div ref={legendRef}>{legend}</div>
		</div>
	)
}

CandleChart.propTypes = {
	legend: PropTypes.string,
	initCandles: PropTypes.array,
	lastCandle: PropTypes.object,
	decimals: PropTypes.number
}

export default CandleChart
