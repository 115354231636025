import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const placeOrder = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/twelve-candles/stock/${data.orderType.toLowerCase()}`,
      data,
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
