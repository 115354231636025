import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getOrders = async (status) => {
  try {
    const response = await axios.get(
      `${API_URL}/twelve-candles/order/all?status=${status}`,
      { withCredentials: true }
    );
    if (response.status === 200) {
      return response.data.orders;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};
