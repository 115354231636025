import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import '../App.css'

const Authenticate = () => {
	const location = useLocation()
	const { roomId } = useParams()
	useEffect(() => {
		const fetchData = async () => {
			const token = new URLSearchParams(location.search).get('token')

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/twelve-candles/room/${roomId}/authenticate?token=${token}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ roomId })
					}
				)
				console.log('response', response)
				if (response.redirected) {
					window.location.href = response.url
				}
				if (response.status === 200) {
					await response.json()
				} else {
					console.log('Authentication failed')
					alert('Authentication failed')
				}
			} catch (error) {
				if (error.response.status === 401) {
					alert('Authentication failed')
				}
			}
		}

		fetchData()
	}, [])

	return null
}

export default Authenticate
