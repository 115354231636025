const API_URL = process.env.REACT_APP_API_URL;

export const authenticatePlayerAndProvideEntry = async (roomId, token) => {
  try {
    const response = await fetch(
      `${API_URL}/twelve-candles/room/${roomId}/authenticate?token=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ roomId }),
      }
    );

    if (response.redirected) {
      window.location.href = response.url;
    }
    if (response?.status === 200) {
      await response.json();
      return true;
    } else {
      console.error("Authentication failed");
      alert("Authentication failed");
      return false;
    }
  } catch (error) {
    if (error.response?.status === 401) {
      alert("Authentication failed");
    }
    console.error("Error during authentication", error);
    return false;
  }
};
