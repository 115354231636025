import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getPositions = async () => {
  try {
    const response = await axios.get(`${API_URL}/twelve-candles/position/all`, {
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data.positions;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};
