import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Avatar,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { fetchJoinedPlayers } from "../../hooks/useGetJoinedPlayersByRoomId";
import { authenticatePlayerAndProvideEntry } from "../../hooks/useAuthenticatePlayerAndProvideEntry";

const PlayersLobby = () => {
  const [players, setPlayers] = useState([]);
  const [room, setRoom] = useState(null);
  const location = useLocation();
  const { roomId } = useParams();
  const [countdown, setCountdown] = useState("00:00:00");
  const [isCountdownFinished, setCountdownFinished] = useState(false);

  useEffect(() => {
    const getJoinedPlayers = async () => {
      const data = await fetchJoinedPlayers(roomId);
      setPlayers(data.players);
      setRoom(data.room);
    };

    getJoinedPlayers();
  }, [roomId]);

  const handleAuthentication = async () => {
    if (!isCountdownFinished) return;
    const token = new URLSearchParams(location.search).get("token");
    const isAuthenticated = await authenticatePlayerAndProvideEntry(
      roomId,
      token
    );
    if (!isAuthenticated) {
      console.log("Failed to authenticate");
    }
  };

  useEffect(() => {
    if (!room) return;
    const startDateTime = new Date(room.startDateTime);
    const interval = setInterval(() => {
      const now = new Date();
      const distance = startDateTime - now;

      if (distance < 0) {
        setCountdownFinished(true);
        clearInterval(interval);
        setCountdown("00:00:00");
      } else {
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(
          `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [room]);

  return (
    <Box m={4}>
      <Typography variant="h6" textAlign="center" mb={4}>
        {!!room && isCountdownFinished ? (
          <span style={{ fontWeight: "bold" }}>Game is Live</span>
        ) : (
          <>
            Game starts in:{" "}
            <span style={{ fontWeight: "bold" }}>{countdown}</span>
          </>
        )}
      </Typography>
      <Grid container spacing={3}>
        {players.map((player, index) => (
          <Grid item xs={3} key={index}>
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Avatar alt={player.fullName} src={player.avatarUrl} />
                <Typography color="textSecondary" variant="h6" gutterBottom>
                  {player.fullName}
                </Typography>
                <Typography color="textSecondary" sx={{ fontWeight: "bold" }}>
                  ₹{player.availableFunds}
                </Typography>
                {/* Add more player details here */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        disabled={!isCountdownFinished}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          marginTop: "20px",
        }}
        onClick={handleAuthentication}
      >
        Enter
      </Button>
    </Box>
  );
};

export default PlayersLobby;
