import React from 'react'
import { ThemeProvider } from '@mui/material'
import './App.css'
import theme from './theme/Theme'
import PlayerProvider from './contexts/PlayerProvider'
import Routes from './Routes'

const App = () => {
	return (
		<React.StrictMode>
			<PlayerProvider>
				<ThemeProvider theme={theme}>
					<Routes />
				</ThemeProvider>
			</PlayerProvider>
		</React.StrictMode>
	)
}

export default App
