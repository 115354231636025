import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import Orders from "./pages/orders/Orders";
import Positions from "./pages/positions/Positions";
import LeaderBoard from "./pages/leaderboard/LeaderBoard";
import TradingPlatform from "./pages/tradingPlatform/TradingPlatform";
import Authenticate from "./pages/Authenticate";
import PlayersLobby from "./pages/playersLobby/PlayersLobby";

const Routing = () => {
  const showHeader = !window.location.pathname.includes("lobby");

  return (
    <div className="Routing">
      <Router>
        {showHeader && <Header />}
        <Routes>
          <Route path="/room/:roomId/authenticate" element={<Authenticate />} />
          <Route path="/room/:roomId/lobby" element={<PlayersLobby />} />
          <Route path="/" element={<Home />}>
            <Route path="/leader-board" element={<LeaderBoard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/positions" element={<Positions />} />
          </Route>
          <Route path="/chart" element={<TradingPlatform />} />
        </Routes>
      </Router>
    </div>
  );
};

export default Routing;
