import axios from "axios";
import { useCallback } from "react";

const useRemoveWatchListItem = () => {
  const removeStockFromWatchlist = useCallback(async (watchlistStockId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/twelve-candles/watchlist?watchlistItemId=${watchlistStockId}`,
        {
          withCredentials: true,
        }
      );
      return response.status;
    } catch (err) {
      console.error(err);
      throw new Error("Something went wrong");
    }
  }, []);

  return {
    removeStockFromWatchlist,
  };
};

export default useRemoveWatchListItem;
