const HEARTBEAT_TIMEOUT = 1000 * 5 + 1000 * 1 // 5 + 1 second
const HEARTBEAT_VALUE = 1

module.exports.heartbeat = (ws, type) => {
	if (!ws) {
		return
	} else if (!!ws.pingTimeout) {
		clearTimeout(ws.pingTimeout)
	}

	if (type === 'close') {
		return
	}

	ws.pingTimeout = setTimeout(() => {
		console.log('Terminating connection due to heartbeat timeout')
		ws.close()

		// business logic for deciding whether or not to reconnect
	}, HEARTBEAT_TIMEOUT)

	const data = new Uint8Array(1)

	data[0] = HEARTBEAT_VALUE
	console.log('ping')
	ws.send(data)
}

module.exports.isBinary = (obj) => {
	return typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Blob]'
}

module.exports.getPlayerTotalProfitLoss = (player) => {
    const pnl =  parseFloat(Number(parseFloat(player.totalProfitLoss)) + Number(parseFloat(player.unrealizedPnL))).toFixed(2);
    if(isNaN(pnl)) {
      if(isNaN(parseFloat(Number(parseFloat(player.totalProfitLoss))))) {
        return 0;
      }
      return parseFloat(Number(parseFloat(player.totalProfitLoss))).toFixed(2);
    }
    return isNaN(pnl) ? 0 : pnl
};
