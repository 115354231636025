export const timeToLocal = (originalTime) => {
	const d = new Date(originalTime * 1000)
	return (
		Date.UTC(
			d.getFullYear(),
			d.getMonth(),
			d.getDate(),
			d.getHours(),
			d.getMinutes(),
			d.getSeconds(),
			d.getMilliseconds()
		) / 1000
	)
}
