import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Loading from '../loading/Loading';
import ExitModal from '../modals/ExitModal';
import { getFixedDecimalPlaces } from '../../helpers/prices';
import { heartbeat, isBinary } from './utils';
import { getPositions } from '../../hooks/useGetPosition';

function createData({ posId, symbol, exchange, orderType, qty, avg, tradeType, lmType, ltp, pnl, posStatus, marketType }) {
  orderType = orderType.toUpperCase();
  lmType = lmType.toUpperCase();

  const fixedDecimalPlaces = getFixedDecimalPlaces(marketType);

  return {
    posId,
    symbol,
    exchange,
    orderType,
    qty,
    avg: avg.toFixed(fixedDecimalPlaces),
    tradeType,
    lmType,
    ltp: ltp.toFixed(fixedDecimalPlaces),
    pnl: pnl.toFixed(2),
    posStatus
  };
}

let ws;

export default function PositionsTable() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [positions, setPositions] = useState([]);
  const [totalPnL, setTotalPnL] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderType, setOrderType] = useState('');
  const [positionData, setPositionData] = useState('');

  const getPnL = (orderType, avgPrice, lastPrice, qty) => {
    return orderType.toLowerCase() === 'buy'
      ? (parseFloat(lastPrice) - parseFloat(avgPrice)) * qty
      : (parseFloat(lastPrice) - parseFloat(avgPrice)) * qty * -1;
  };

  const handlePositionsResponse = (positions) => {
    const allPositions = [];
    positions?.forEach((position) => {
      const orderType = position.posType === 'long' ? 'buy' : 'sell';
      const avgPrice = position.posType === 'long' ? position.buyOrderId.price : position.sellOrderId.price;
      const lastPrice =
        position.posType === 'long'
          ? position.posStatus === 'Active'
            ? position.buyOrderId.scripId.lastPrice
            : position.sellOrderId.price
          : position.posStatus === 'Active'
          ? position.sellOrderId.scripId.lastPrice
          : position.buyOrderId.price;

      allPositions.push(
        createData({
          posId: position._id,
          symbol: position.buyOrderId.scripId.symbol,
          exchange: position.buyOrderId.scripId.exchange,
          orderType,
          qty: position.buyOrderId.qty,
          avg: parseFloat(avgPrice),
          tradeType: position.buyOrderId.productType,
          lmType: position.buyOrderId.priceType,
          ltp: lastPrice,
          pnl: getPnL(orderType, avgPrice, lastPrice, position.buyOrderId.qty),
          posStatus: position.posStatus,
          marketType: position.buyOrderId.scripId.type
        })
      );
    });

    return allPositions;
  };

  const fetchPositions = async () => {
    setLoading(true);
    const positionsData = await getPositions();
    const allPositions = handlePositionsResponse(positionsData);
    setPositions(allPositions);
    handleSetTotalPnL(allPositions);
    setLoading(false);
  };

  const handleExitPosition = (position) => {
    setOpen(true);
    setOrderType(position.orderType.toLowerCase() === 'buy' ? 'Sell' : 'Buy');
    setPositionData(position);
  };

  const updatePosition = (newData) => {
    setPositions((prev) => {
      const tempPrev = JSON.parse(JSON.stringify(prev));

      return tempPrev.map((position) => {
        if (position.posId === newData.positionId) {
          const fixedDecimalPlaces = getFixedDecimalPlaces(position.type);
          return {
            ...position,
            ltp: newData.lp.toFixed(fixedDecimalPlaces),
            pnl: getPnL(position.orderType, position.avg, newData.lp, position.qty)?.toFixed(2)
          };
        } else {
          return position;
        }
      });
    });
  };

  const handleSetTotalPnL = (allPositions = []) => {
    setTotalPnL(allPositions.reduce((acc, position) => acc + parseFloat(position.pnl), 0).toFixed(2));
  };

  const handleExitSuccess = () => {
    setTimeout(() => {
      fetchPositions();
      ws.close();
    }, 2000);
  };

  useEffect(() => {
    if (positions.length > 0) {
      handleSetTotalPnL(positions);
    }
  }, [positions]);

  useEffect(() => {
    if (positions.length) {
      ws = new WebSocket(`${process.env.REACT_APP_TWELVE_CANDLES_WS_URL}/socket`);

      ws.onopen = () => {
        console.log('Connected to websocket!!');
        ws.send(JSON.stringify({ type: 'Positions is now open' }));
        ws.send(
          JSON.stringify({
            type: 'getLivePositions'
          })
        );
      };
      ws.onclose = () => {
        console.log('Positions Connection closed!!');
      };

      ws.onerror = (error) => {
        console.log('Positions WebSocket error: ', error);
      };

      ws.onmessage = (ev) => {
        if (isBinary(ev.data)) {
          heartbeat(ws);
        } else {
          const messageData = JSON.parse(ev.data);

          console.log('messageData', messageData);

          if (messageData.type === 'positions') {
            if (positions?.length > 0) {
              updatePosition(messageData.data);
            }
          }
        }
      };
    }
    return () => {
      if (ws) {
        console.log('closing connection-', ws);
        ws.close();
      } else {
        console.log('no connection to close');
      }
    };
  }, [positions?.length]);

  useEffect(() => {
    fetchPositions();
  }, []);

  return (
    <TableContainer component={Paper}>
      <ExitModal orderType={orderType} open={open} setOpen={setOpen} positionData={positionData} onExitSuccess={handleExitSuccess} />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Symbol
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Product type
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              B/S
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Exchange
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Price type
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Qty
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="right"
            >
              Avg
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="right"
            >
              Ltp
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="right"
            >
              P&L
            </TableCell>
            <TableCell
              sx={{
                textTransform: 'uppercase',
                fontSize: isMobile ? '0.7rem' : 'inherit'
              }}
              align="left"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Loading />
          ) : positions.length > 0 ? (
            positions.map((position) => (
              <TableRow
                key={position.posId}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  opacity: position.posStatus === 'Active' ? '1' : '0.6',
                  background: position.posStatus === 'Active' ? '#fff' : '#c4c4cc70'
                }}
              >
                <TableCell align="left">{position.symbol}</TableCell>
                <TableCell align="left">{position.tradeType}</TableCell>
                <TableCell align="left">
                  <Typography
                    sx={{
                      background: position.orderType.toLowerCase() === 'buy' ? '#5a55ff20' : '#d4372520',
                      color: position.orderType.toLowerCase() === 'buy' ? '#5a55ff' : '#d43725',
                      textAlign: 'center',
                      borderRadius: '5px',
                      fontSize: isMobile ? '0.7rem' : '0.9rem',
                      padding: '0.2rem 0.5rem'
                    }}
                  >
                    {position.orderType}
                  </Typography>
                </TableCell>
                <TableCell align="left">{position.exchange}</TableCell>
                <TableCell align="left">{position.lmType}</TableCell>
                <TableCell
                  sx={{
                    color: position.orderType.toLowerCase() === 'buy' ? '#5a55ff' : '#d43725'
                  }}
                  align="left"
                >
                  {position.qty}
                </TableCell>
                <TableCell align="right">{position.avg}</TableCell>
                <TableCell align="right">{position.ltp}</TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: position.pnl > 0 ? '#0cd46a' : '#d43725'
                  }}
                >
                  {position.pnl}
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    onClick={() => handleExitPosition(position)}
                    disabled={position.posStatus.toLowerCase() === 'active' ? false : true}
                    sx={{
                      fontSize: isMobile ? '0.6rem' : '0.8rem!important',
                      fontWeight: '600',
                      boxShadow: 'none',
                      background: 'transparent',
                      color: '#d43725',
                      border: position.posStatus.toLowerCase() === 'active' ? '1px solid #d43725' : 'none',
                      '&:hover': {
                        color: '#d43725',
                        boxShadow: 'none',
                        background: '#d43725',
                        color: '#fff'
                      }
                    }}
                  >
                    EXIT
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell component="th" scope="row">
                No positions
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="left"></TableCell>
            <TableCell colSpan={3} align="left">
              <Typography variant="body1">Total P/L</Typography>
            </TableCell>
            <TableCell colSpan={3} align="left" sx={{ fontWeight: '' }}>
              <Typography
                variant="body1"
                sx={{
                  color: totalPnL > 0 ? '#0cd46a' : '#d43725'
                }}
              >
                {totalPnL}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
