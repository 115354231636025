import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const searchStocks = async (inputStock, marketType) => {
  try {
    const response = await axios.get(
      `${API_URL}/twelve-candles/scrip/search?scriptName=${inputStock}&type=${
        marketType === "all" ? "" : marketType
      }`,
      {
        withCredentials: true,
      }
    );
    if (response.status === 200) {
      return response.data.data.slice(0, 10);
    }
  } catch (error) {
    console.error("Error searching stocks:", error);
    throw error;
  }
};
