import React from "react";
import IconButton from "@mui/material/IconButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Stack from "@mui/material/Stack";
import EditNoteIcon from "@mui/icons-material/EditNote";
const VerticalToolsMenu = ({ onMenuClick, showWatchlist, showNotes }) => {
  return (
    <Stack spacing={2}>
      <IconButton
        color={showWatchlist ? "error" : "black"} // Change color to red if showWatchlist is true
        onClick={() => onMenuClick("watchlist")}
      >
        <ListAltIcon />
      </IconButton>
      {/* <IconButton
        color={showNotes ? "error" : "black"} // Change color to red if showWatchlist is true
        onClick={() => onMenuClick("notes")}
      >
        <EditNoteIcon />
      </IconButton> */}
    </Stack>
  );
};

export default VerticalToolsMenu;
