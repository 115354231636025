import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Paper, Stack, Typography, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useRemoveWatchListItem from "../../hooks/useRemoveWatchListItem";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0.2rem",
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  borderBottom: "1px solid #B0A8B9",
  borderRadius: "0px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.mode === "dark" ? "#2A3037" : "#f5f5f5",
  },
}));

const WatchlistItem = ({
  stock,
  setOpen,
  setStock,
  setOrderType,
  setUserWatchlist,
  setShowWatchlist,
}) => {
  const [hoverIndex, setHoverIndex] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const navigate = useNavigate();
  const { removeStockFromWatchlist } = useRemoveWatchListItem();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowChart = (stock) => {
    if (isMobileView) {
      setShowWatchlist(false);
    }
    navigate(`/chart?symbol=${stock.scriptId?.exchange}:${stock.symbol}`);
  };

  const handleRemoveStockToWatchlist = async (watchlistStockId) => {
    try {
      const status = await removeStockFromWatchlist(watchlistStockId);
      if (status === 200) {
        setUserWatchlist((prevState) =>
          prevState.filter((stock) => stock._id !== watchlistStockId)
        );
        alert("Removed");
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    }
  };

  return (
    <Item onClick={() => handleShowChart(stock)}>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        onMouseOver={() => setHoverIndex(stock._id)}
        onMouseLeave={() => setHoverIndex(-1)}
      >
        <Grid item xs={8}>
          <Typography>{stock.symbol}</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          {hoverIndex === stock._id ? (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack
                sx={{
                  background: "#fff",
                  cursor: "pointer",
                  boxShadow: "0px 0px 3px 2px #bddfc3",
                }}
              >
                <DeleteIcon
                  onClick={() => handleRemoveStockToWatchlist(stock._id)}
                />
              </Stack>
            </Stack>
          ) : null}
        </Grid>
      </Grid>
    </Item>
  );
};

export default WatchlistItem;
