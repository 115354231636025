import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Loading from "../../components/loading/Loading";
import CandleChart from "../tradingChart/CandleChart";
import TradingChartHeader from "./TradingChartHeader";
import axios from "axios";
import BuySellModal from "../../components/modals/BuySellModal";
import useGetAllWatchList from "../../hooks/useGetAllWatchList";
function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  // Add leading zeros to month and day if necessary
  const monthString = month.toString().padStart(2, "0");
  const dayString = day.toString().padStart(2, "0");

  // Format the date string as yyyy-mm-dd
  const dateString = `${year}-${monthString}-${dayString}`;

  return dateString;
}

function getDateXDaysAgo(numDays) {
  const today = new Date();
  const pastDate = new Date(today.getTime() - numDays * 24 * 60 * 60 * 1000);
  const year = pastDate.getFullYear();
  const month = pastDate.getMonth() + 1;
  const day = pastDate.getDate();

  // Add leading zeros to month and day if necessary
  const monthString = month.toString().padStart(2, "0");
  const dayString = day.toString().padStart(2, "0");

  // Format the date string as yyyy-mm-dd
  const dateString = `${year}-${monthString}-${dayString}`;

  return dateString;
}

function TradingChart({
  script,
  lastCandlestick,
  timeFrame,
  onTimeFrameChange,
  scripDefault,
}) {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(getDateXDaysAgo(10));
  const [toDate, setToDate] = useState(getCurrentDate());
  const location = useLocation();
  const scrip = new URLSearchParams(location.search).get("symbol");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [stock, setStock] = useState({});
  const [orderType, setOrderType] = useState("");
  const { userWatchlist } = useGetAllWatchList();
  console.log("Watchlist data:", userWatchlist);
  const getHistoricalScripData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/twelve-candles/historical/get?symbol=${
          scripDefault ? scripDefault : scrip
        }&timeFrame=${timeFrame}&fromDate=${fromDate}`,
        {
          withCredentials: true,
        }
      );

      console.log("response", response);
      if (response.status === 200) {
        let allData = [];
        response.data.data.forEach((candle) => {
          allData.push({
            time: candle.time,
            open: candle.open,
            high: candle.max,
            low: candle.min,
            close: candle.close,
          });
        });
        setLoading(false);
        setData(allData);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistoricalScripData();
  }, [scrip, timeFrame]);

  const handleTimeFrameChange = (value) => {
    onTimeFrameChange(value);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <BuySellModal
            open={open}
            setOpen={setOpen}
            orderType={orderType}
            stock={script}
          />
          <TradingChartHeader
            timeFrame={timeFrame}
            onTimeFrameChange={handleTimeFrameChange}
            stock={scrip}
            setStock={setStock}
            setOrderType={setOrderType}
            setOpen={setOpen}
          />
          <CandleChart
            initCandles={data}
            lastCandle={lastCandlestick}
            legend={scrip}
          />
        </>
      )}
    </Box>
  );
}

export default TradingChart;
