const API_URL = process.env.REACT_APP_API_URL;

export const fetchJoinedPlayers = async (roomId) => {
  try {
    const response = await fetch(
      `${API_URL}/twelve-candles/room/${roomId}/players`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response?.status === 200) {
      const roomPlayersResponseData = await response.json();
      if (roomPlayersResponseData.success) {
        return roomPlayersResponseData.data;
      }
    }
  } catch (error) {
    console.error("Error while fetching players", error);
  }
  return { players: [], room: null };
};
