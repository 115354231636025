import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import Loading from '../../components/loading/Loading';
import { heartbeat, isBinary } from '../../components/tables/utils';
import LeaderBoardTable from '../../components/tables/LeaderBoardTable';
import { useMediaQuery, useTheme } from '@mui/material';
import { getLeaderBoard } from '../../hooks/useGetLeaderBoard';
// import { attemptReconnect } from '../../wss/reconnect';
let ws;

export default function LeaderBoard() {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const fetchLeaderBoard = async () => {
    try {
      const data = await getLeaderBoard();
      setLeaderBoard(data);
      setLoading(false);
      setRefresh(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateLeaderBoard = (data) => {
    setLeaderBoard((prev) => {
      return prev.map((player) => {
        if (player._id === data._id) {
          player.unrealizedPnL = data.unrealizedPnL;
        }
        return player;
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchLeaderBoard();
  }, []);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      fetchLeaderBoard();
    }
  }, [refresh]);

  useEffect(() => {
    console.log('leaderBoard', leaderBoard.length);
    if (leaderBoard.length) {
      ws = new WebSocket(`${process.env.REACT_APP_TWELVE_CANDLES_WS_URL}/socket`);

      ws.onopen = () => {
        console.log('Connected to websocket!!');
        ws.send(JSON.stringify({ type: 'Leader Board is now open' }));
        ws.send(
          JSON.stringify({
            type: 'getLiveLeaderBoard'
          })
        );
      };
      ws.onclose = (event) => {
        console.log('Leader Board Connection closed!!', event);
        if (event.code === 1006) {
          console.log('Abnormal closure detected. Possible causes: network issues, server crash, or proxy/firewall interruption.');
          // Consider adding more diagnostic logging or actions here
        } else {
          console.log('Normal closure detected.');
          // Consider adding more diagnostic logging or actions here
          // attemptReconnect();
        }
      };

      ws.onerror = (error) => {
        if (error instanceof Event) {
          console.log('Leader Board WebSocket error instance of Event: ', error, error.type);
        } else {
          console.log('Leader Board WebSocket error: ', error.message);
        }
      };

      ws.onmessage = (ev) => {
        console.log('onmessage');
        if (isBinary(ev.data)) {
          console.log('binary data', ev.data);
          heartbeat(ws);
        } else {
          const messageData = JSON.parse(ev.data);
          console.log('messageData', messageData, leaderBoard.length);

          if (messageData.type === 'leaderBoardPlayer') {
            if (leaderBoard?.length > 0) {
              updateLeaderBoard(messageData.data);
            }
          }
        }
      };
      console.log('111');
    }
    return () => {
      if (ws) {
        console.log('closing connection');
        ws.close();
      } else {
        console.log('no connection to close');
      }
    };
  }, [leaderBoard.length]);

  return (
    <Box
      sx={{
        maxWidth: '100%',
        mx: 'auto',
        px: 2,
        paddingRight: '0px',
        paddingLeft: '10px',
        marginLeft: '0px',
        marginRight: '0px'
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1} sx={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
        <Typography
          sx={{
            textAlign: isMobile ? 'center' : 'left',
            p: 2,
            color: '#000',
            fontSize: isMobile ? '1.2rem' : '1.5rem'
          }}
          variant="h5"
        >
          Leader Board
        </Typography>
        <RefreshIcon
          sx={{
            cursor: 'pointer',
            fontSize: isMobile ? '1.5rem' : '2rem',
            ml: isMobile ? 0 : 2
          }}
          onClick={() => setRefresh(true)}
        />
      </Stack>
      {loading ? <Loading /> : <LeaderBoardTable leaderBoard={leaderBoard} />}
    </Box>
  );
}
