import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery hook
import Watchlist from "../../components/watchlist/Watchlist";
import TradingChart from "./TradingChart";
import VerticalToolsMenu from "../../components/VerticalToolsMenu";
import useGetAllWatchList from "../../hooks/useGetAllWatchList";
// import Notes from "../../components/Notes"; // Import Notes component

const Item = styled(Paper)(({ theme, nopadding }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%", // Set height to full viewport height
  borderRadius: "0",
  padding: nopadding ? 0 : theme.spacing(1),
  boxShadow: "none", // Remove shadow
  border: `1px solid ${theme.palette.divider}`, // Add border
}));

let ws;
const HEARTBEAT_TIMEOUT = 1000 * 5 + 1000 * 1; // 5 + 1 second
const HEARTBEAT_VALUE = 1;

const TradingPlatform = () => {
  const [lastCandlestick, setLastCandlestick] = useState({});
  const [timeFrame, setTimeFrame] = useState("D");
  const [showWatchlist, setShowWatchlist] = useState(false);
  // const [showNotes, setShowNotes] = useState(false); // Commented out notes related state
  const [scrip, setScrip] = useState(""); // add scrip state

  const location = useLocation();
  const symbolParam = new URLSearchParams(location.search).get("symbol");

  const { userWatchlist, fetchUserWatchList, setUserWatchlist } =
    useGetAllWatchList(); // Use the custom hook

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Detect mobile view

  useEffect(() => {
    if (symbolParam) {
      setScrip(symbolParam);
    }
  }, [symbolParam]);

  const handleTimeFrameChange = (timeFrame) => {
    setTimeFrame(timeFrame);
  };

  const handleMenuClick = (menu) => {
    if (menu === "watchlist") {
      setShowWatchlist(!showWatchlist);
      // setShowNotes(false); // Commented out notes related state
    }
  };

  const heartbeat = (type) => {
    if (!ws) {
      return;
    } else if (!!ws.pingTimeout) {
      clearTimeout(ws.pingTimeout);
    }

    if (type === "close") {
      return;
    }

    ws.pingTimeout = setTimeout(() => {
      console.log("Terminating connection due to heartbeat timeout");
      ws.close();
      // business logic for deciding whether or not to reconnect
    }, HEARTBEAT_TIMEOUT);

    const data = new Uint8Array(1);
    data[0] = HEARTBEAT_VALUE;
    console.log("ping");
    ws.send(data);
  };

  const isBinary = (obj) => {
    return (
      typeof obj === "object" &&
      Object.prototype.toString.call(obj) === "[object Blob]"
    );
  };

  useEffect(() => {
    const fetchWatchlist = async () => {
      await fetchUserWatchList();
    };
    fetchWatchlist();
  }, []);

  useEffect(() => {
    if (userWatchlist.length > 0 && !scrip) {
      setScrip(
        `${userWatchlist[0].scriptId.exchange}:${userWatchlist[0].scriptId.symbol}`
      );
    }
  }, [userWatchlist]);

  useEffect(() => {
    if (!scrip) return;
    ws = new WebSocket(`${process.env.REACT_APP_TWELVE_CANDLES_WS_URL}/socket`);
    ws.onopen = () => {
      console.log("Connected to websocket!!");
      const setMarketData = {
        type: "setMarket",
        data: {
          market: scrip,
          marketOptions: {
            timeframe: timeFrame,
          },
        },
      };
      ws.send(JSON.stringify(setMarketData));
    };
    ws.onclose = () => {
      console.log("Connection closed!!");
    };

    ws.onerror = (error) => {
      console.log("WebSocket error: ", error);
    };

    ws.onmessage = (ev) => {
      if (isBinary(ev.data)) {
        heartbeat();
      } else {
        const messageData = JSON.parse(ev.data);
        console.log("messageData", messageData);
        if (messageData.type === "disconnected") {
          console.log("disconnected");
          ws.close();
        }
        if (messageData.type === "priceChange") {
          setLastCandlestick({
            time: messageData.data.time,
            open: messageData.data.open,
            high: messageData.data.max,
            low: messageData.data.min,
            close: messageData.data.close,
          });
        }
        if (messageData.type === "watchlist") {
          setUserWatchlist(messageData.data.scrips);
        }
      }
    };

    return () => {
      console.log("closing connection");
      heartbeat("close");
      ws.close();
    };
  }, [scrip]);

  useEffect(() => {
    if (ws?.readyState === 1) {
      const setTimeFrameData = {
        type: "setTimeframe",
        data: {
          market: scrip,
          timeframe: timeFrame,
        },
      };
      ws.send(JSON.stringify(setTimeFrameData));
    }
  }, [timeFrame]);

  return (
    <Box sx={{ display: "flex" }}>
      <Grid container spacing={0}>
        <Grid item width={50}>
          <Item nopadding="true">
            <VerticalToolsMenu
              onMenuClick={handleMenuClick}
              showWatchlist={showWatchlist}
              // showNotes={showNotes} // Pass the showNotes state as a prop
            />
          </Item>
        </Grid>
        <Grid item width={"calc(100% - 50px)"}>
          <Grid container spacing={0} sx={{ height: "100%" }}>
            {/* Set container height to full viewport height */}
            {showWatchlist && (
              <Grid item xs={12} md={3} sx={{ height: "100%" }}>
                <Item>
                  <Watchlist
                    userWatchlist={userWatchlist}
                    setUserWatchlist={setUserWatchlist}
                    setShowWatchlist={setShowWatchlist}
                  />
                </Item>
              </Grid>
            )}
            {/* Commented out the Notes component */}
            {/* {showNotes ? (
              <Grid item xs={12} sx={{ height: "100%" }}>
                <Item>
                  <Notes />
                </Item>
              </Grid>
            ) : */}

            {/* Removed notes functionality */}
            {!isMobile || !showWatchlist ? ( // Hide chart if mobile view and watchlist is shown
              <Grid
                item
                xs={12}
                md={showWatchlist ? 9 : 12}
                sx={{ height: "100%" }}
              >
                <Item nopadding="true">
                  {scrip && (
                    <TradingChart
                      script={userWatchlist?.find(
                        (item) => item.scriptId.proName === scrip
                      )}
                      lastCandlestick={lastCandlestick}
                      timeFrame={timeFrame}
                      onTimeFrameChange={handleTimeFrameChange}
                      scripDefault={scrip}
                    />
                  )}
                </Item>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TradingPlatform;
