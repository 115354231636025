import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FullscreenButton from "../../components/FullscreenButton";
import { useState } from "react";
const TradingChartHeader = ({
  timeFrame,
  onTimeFrameChange,
  stock,
  setOpen,
  setStock,
  setOrderType,
}) => {
  const handleTimeFrameChange = (event) => {
    onTimeFrameChange(event.target.value);
  };
  const handleBuySellStock = (stock, type) => {
    setOpen(true);
    setOrderType(type);
    setStock(stock);
  };
  console.log("Stock", stock);
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="select-timeframe-label">Time Frame</InputLabel>
        <Select
          labelId="select-timeframe-label"
          id="select-timeframe"
          value={timeFrame}
          label="Time Frame"
          onChange={handleTimeFrameChange}
        >
          <MenuItem value={"1"}>1 minute</MenuItem>
          <MenuItem value={"3"}>3 minutes</MenuItem>
          <MenuItem value={"5"}>5 minutes</MenuItem>
          <MenuItem value={"10"}>10 minutes</MenuItem>
          <MenuItem value={"15"}>15 minutes</MenuItem>
          <MenuItem value={"30"}>30 minutes</MenuItem>
          <MenuItem value={"45"}>45 minutes</MenuItem>
          <MenuItem value={"60"}>1 hour</MenuItem>
          <MenuItem value={"120"}>2 hours</MenuItem>
          <MenuItem value={"180"}>3 hours</MenuItem>
          <MenuItem value={"240"}>4 hours</MenuItem>
          <MenuItem value={"D"}>1 Day</MenuItem>
          <MenuItem value={"W"}>1 Week</MenuItem>
          <MenuItem value={"M"}>1 Month</MenuItem>
        </Select>
      </FormControl>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleBuySellStock(stock, "Buy")}
          sx={{ backgroundColor: "#1976d2", color: "white", marginRight: 1 }}
        >
          Buy
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleBuySellStock(stock, "Sell")}
          sx={{ backgroundColor: "red", color: "white" }}
        >
          Sell
        </Button>
        <FullscreenButton />
      </Grid>
    </Grid>
  );
};

export default TradingChartHeader;
