import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Loading from "../loading/Loading";
import { getOrders } from "../../hooks/useGetOrders";

function createData(
  orderId,
  time,
  symbol,
  exchange,
  orderType,
  qty,
  avg,
  tradeType,
  lmType,
  status
) {
  let date = new Date(time);
  time =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()) +
    ":" +
    date.getSeconds();
  orderType = orderType.toUpperCase();
  status = status.toUpperCase();

  return {
    orderId,
    time,
    symbol,
    exchange,
    orderType,
    qty,
    avg,
    tradeType,
    lmType,
    status,
  };
}

export default function OrdersTable({ status, refresh, setRefresh }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    const ordersData = await getOrders(status);
    const allOrders = ordersData.map((order) =>
      createData(
        order._id,
        order.createdAt,
        order.scripId.symbol,
        order.scripId.exchange,
        order.orderType,
        order.qty,
        order.price,
        order.productType,
        order.priceType,
        order.orderStatus
      )
    );
    setOrders(allOrders);
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (refresh) fetchOrders();
  }, [refresh]);

  return (
    <Container
      maxWidth="lg"
      sx={{ paddingTop: "2px", paddingRight: "0px", paddingLeft: "0px" }}
    >
      <TableContainer
        component={Paper}
        sx={{ width: "100%", paddingTop: "0rem" }}
      >
        <Table
          style={{ tableLayout: "auto" }}
          sx={{ minWidth: 100 }}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell align="right">Order type</TableCell>
              <TableCell align="right">Symbol</TableCell>
              <TableCell align="right">Exchange</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Avg</TableCell>
              <TableCell align="right">Product type</TableCell>
              <TableCell align="right">Price type</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Loading />
                </TableCell>
              </TableRow>
            ) : orders.length > 0 ? (
              orders.map((order) => (
                <TableRow key={order.orderId}>
                  <TableCell style={{ padding: "0.5rem" }}>
                    {order.time}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    <Typography
                      sx={{
                        background:
                          order.orderType.toLowerCase() === "buy"
                            ? "#5a55ff20"
                            : "#d4372520",
                        color:
                          order.orderType.toLowerCase() === "buy"
                            ? "#5a55ff"
                            : "#d43725",
                        textAlign: "center",
                        borderRadius: "5px",
                        fontSize: "0.9rem",
                        padding: "0.2rem 0.5rem",
                      }}
                    >
                      {order.orderType}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.symbol}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.exchange}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.qty}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.avg}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.tradeType}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    {order.lmType}
                  </TableCell>
                  <TableCell align="right" style={{ padding: "0.5rem" }}>
                    <Typography
                      sx={{
                        background:
                          order.status.toLowerCase() === "executed"
                            ? "#23d16020"
                            : "#d4372520",
                        color:
                          order.status.toLowerCase() === "executed"
                            ? "#23d160"
                            : "#d43725",
                        textAlign: "center",
                        borderRadius: "5px",
                        fontSize: "0.9rem",
                        padding: "0.2rem 0.5rem",
                      }}
                    >
                      {order.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="left">
                  No orders
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
