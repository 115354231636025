import { Box, Container } from "@mui/material";
import React from "react";
import PositionsTable from "../../components/tables/PositionsTable";

function Positions() {
  return (
    <Container maxWidth="lg">
      <Box
        style={{
          paddingTop: "20px",
          "@media (maxWidth: 600px)": {
            paddingTop: "10px",
          },
        }}
      >
        <PositionsTable />
      </Box>
    </Container>
  );
}

export default Positions;
