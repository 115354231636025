import { Box, Typography, Tabs, Tab, Stack } from "@mui/material";
import React, { useState } from "react";
import OrdersTable from "../../components/tables/OrdersTable";
import PropTypes from "prop-types";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMediaQuery, useTheme } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            paddingRight: "0px",
            paddingLeft: "10px",
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Orders() {
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        mx: "auto",
        px: 2,
        paddingRight: "0px",
        paddingLeft: "0px",
        marginLeft: "0px",
        marginRight: "0px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          flexWrap: isMobile ? "wrap" : "nowrap",
          paddingX: isMobile ? 0 : 2, // Remove left and right padding for mobile view
        }}
      >
        <Typography
          sx={{
            textAlign: isMobile ? "center" : "left",
            p: 2,
            color: "#000",
            fontSize: isMobile ? "1.2rem" : "1.5rem",
          }}
          variant="h5"
        >
          Orders
        </Typography>
        <RefreshIcon
          sx={{
            cursor: "pointer",
            fontSize: isMobile ? "1.5rem" : "2rem",
            ml: isMobile ? 0 : 2,
          }}
          onClick={() => setRefresh(true)}
        />
      </Stack>
      <Box
        sx={{
          width: "100%",
          paddingRight: "0px",
          paddingLeft: "0px",
          marginLeft: "0px",
          marginRight: "0px",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            paddingRight: "0px",
            paddingLeft: "0px",
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
            aria-label="secondary tabs example"
            variant={isTablet ? "fullWidth" : "standard"}
          >
            <Tab
              sx={{
                color: "#c4c4cc",
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
              label="Open"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                color: "#c4c4cc",
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
              label="Executed"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OrdersTable
            status={"Open"}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrdersTable
            status={"Executed"}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default Orders;
